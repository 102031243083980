import React from "react";
import { AppBar, Avatar, Badge, Box, Button, Container, createStyles, Dialog, Divider, Fade, IconButton, makeStyles, Menu, Paper, TextField, Theme, Toolbar, Tooltip, Typography, useTheme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PublishIcon from '@material-ui/icons/Publish';

import { useCookies } from 'react-cookie';
import jwt_decode from "jwt-decode";
import { Link, Route, Switch } from "react-router-dom";

import CustomPaper from './CustomPaper';
import Upload from './upload/page/Upload';

import Records from "./demo/page/Records";
import LJTop from './demo/page/LJTop';

import DemoAnalysis from './demo/DemoAnalysis';

import User from './user/User';

import ChatComp from './chat/ChatComp';

import Rules from './rules/Rules';

import UserBanned from './user/page/UserBanned';
import Profile from './user/page/Profile';
import Users from './user/page/Users';

import LoginSuccess from './user/LoginSuccess';
import OnlineUsersComp from './user/OnlineUsersComp';

import { API_URL, FRONTEND_URL, NEW_API_URL } from './Config';

import Home from './Home';
import { useTranslation } from "react-i18next";
import Top from "./demo/page/Top";
import TabbedDemosWaitlist from "./demo/page/TabbedDemosWaitlist";
import Challenges from "./challenge/page/Challenges";
import CreateChallenge from "./challenge/page/CreateChallenge";
import ActiveChallengeLeaderboard from "./challenge/ActiveChallengeLeaderboard";
// import Pfizer from "./Pfizer";
// import Wrong from "./Wrong";
import MapData from "./map/page/MapData";
import ServerStatusComp from "./server/ServerStatusComp";
import ServerTopComp from "./top/ServerTopComp";
import { isAdmin } from "./Util";
import ServerAdmin from "./server/page/ServerAdmin";
import Shop from "./shop/page/Shop";
import CardForm from "./checkout/CardForm";
import Success from "./shop/page/Success";
import UserType from "./user/UserType";

import Privacy from "./Privacy";
import ReactCountryFlag from "react-country-flag";
import CryptoJS from "crypto-js";
import Ratings from "./server/page/Ratings";
import TabbedTopComp from "./top/TabbedTopComp";

export interface RoutableAppProps {
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navBar: {
      borderBottom: "1.6px solid rgb(78, 29, 11)",
      borderTop: "1.6px solid rgb(78, 29, 11)",

      backgroundColor: "rgb(20, 20, 20) !important",
    },
    rootWrapper: {
      display: "flex",
      justifyContent: "center",
    },
    contentPaper: {
      backgroundColor: "#282828",
      width: "90%",
      height: "100%",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    threeColumnLayout: {
      marginTop: "10px",
      marginBottom: "10px",
      display: "grid",
      gap: theme.spacing(1),
      gridTemplateColumns: "1fr 5fr 1fr",
    },
    leftColumnWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3)
    },
    sectionTitle: {
      padding: theme.spacing(2),
    },
    banner: {
      pointerEvents: "none",
      height: "auto",
      width: "100%"
    },
    bannerWrapper: {
      pointerEvents: "none",
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#010101"
    },
    rlink: {
      textDecoration: "none"
    },
    discordIcon: {
      width: theme.spacing(6),
      height: theme.spacing(4.5),
      transition: "filter 0.5s",
      transitionTimingFunction: "ease-in-out",
    },
    discordIconBtn: {
      width: theme.spacing(6),
      height: theme.spacing(4.5),
    }
  })
);
export default function RoutableApp(props: RoutableAppProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const [cookies, set_cookies, remove_cookies] = useCookies(['jwt']);
  const [jwt, set_jwt] = React.useState<string | null>(cookies["jwt"]);

  const [decodedJwtData, set_decodedJwtData] = React.useState<User | undefined>(jwt ? (jwt_decode(jwt) as User) : undefined);

  const jwt_setter = React.useCallback((jwt: string) => {
    const expDate = new Date();
    expDate.setFullYear(expDate.getFullYear() + 1);

    set_cookies("jwt", jwt, {
      path: "/",
      secure: process.env.NODE_ENV === "production",
      httpOnly: false,
      domain: process.env.NODE_ENV === "production" ? ".romanian-jumpers.com" : "localhost",
      expires: expDate
    });

    set_jwt(jwt);
    set_decodedJwtData(jwt_decode(jwt) as User);
  }, [set_cookies]);

  const [watchlistNotificationCount, set_watchlistNotificationCount] = React.useState(0);
  const [ljWatchlistNotificationCount, set_ljWatchlistNotificationCount] = React.useState(0);

  const [onlineUsers, set_onlineUsers] = React.useState<User[]>([]);
  const [hoveringDiscord, set_hoveringDiscord] = React.useState(false);

  const anchofProfileRef = React.useRef<HTMLButtonElement>(null);
  const [profileMenuOpen, set_profileMenuOpen] = React.useState(false);

  const handleProfileMenuToggle = () => {
    set_profileMenuOpen((prevOpen) => !prevOpen);
  };

  const handleProfileMenuClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchofProfileRef.current && anchofProfileRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    set_profileMenuOpen(false);
  };

  const anchofDemosRef = React.useRef<HTMLButtonElement>(null);
  const [demosMenuOpen, set_demosMenuOpen] = React.useState(false);

  const handleDemosMenuToggle = () => {
    set_demosMenuOpen((prevOpen) => !prevOpen);
  };

  const handleDemosMenuClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchofDemosRef.current && anchofDemosRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    set_demosMenuOpen(false);
  };

  const reloadWaitlistCount = React.useCallback(() => {
    if (!jwt || !decodedJwtData) {
      return;
    }

    fetch(`${NEW_API_URL}/waitlist/kz/count`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        const count = Number.parseInt(await response.text());

        set_watchlistNotificationCount(count);
      }
    });
  }, [jwt, decodedJwtData]);

  const reloadLJWaitlistCount = React.useCallback(() => {
    if (!jwt || !decodedJwtData) {
      return;
    }

    fetch(`${NEW_API_URL}/waitlist/lj/count`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        const count = Number.parseInt(await response.text());

        set_ljWatchlistNotificationCount(count);
      }
    });
  }, [jwt, decodedJwtData]);

  React.useEffect(() => {
    reloadWaitlistCount();
    reloadLJWaitlistCount();
  }, [reloadWaitlistCount, reloadLJWaitlistCount]);

  React.useEffect(() => {
    if (!jwt || !decodedJwtData) {
      return;
    }

    fetch(`${API_URL}/onlineUsers`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        set_onlineUsers(await response.json());
      }
    });
  }, [jwt, decodedJwtData]);

  const [loginMethodDialogOpen, set_loginMethodDialogOpen] = React.useState(false);
  const [steamIdTxt, set_googleSteamIdTxt] = React.useState("");

  const [steamIdPassword, set_steamIdPassword] = React.useState<string | null>(null);
  const [passwordLoginError, set_passwordLoginError] = React.useState(false);

  const googleSidValid = React.useMemo(() => {
    return /^STEAM_[0-9]:[0-9]:[0-9]*$/.test(steamIdTxt);
  }, [steamIdTxt]);

  const onLogin = () => {
    set_loginMethodDialogOpen(true);
  };

  const onPasswordLogin = React.useCallback(() => {
    if (!steamIdPassword) {
      return;
    }

    fetch(`${API_URL}/password_login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sid: steamIdTxt.trim(),
        passwordMD5: CryptoJS.MD5(steamIdPassword).toString()
      })
    }).then(async (response) => {
      if (response.status !== 200) {
        console.error("PASSWORD LOGIN FAILED", response.statusText);
      } else {
        interface PasswordLoginReply {
          success: boolean,
          banned: boolean,
          token: string,
        };

        const reply = (await response.json()) as PasswordLoginReply;

        if (!reply.success) {
          set_passwordLoginError(true);

          return;
        }

        set_loginMethodDialogOpen(false);

        if (reply.banned) {
          window.location.href = FRONTEND_URL + `#/banned`;
        } else {
          window.location.href = FRONTEND_URL + `#/login/${reply.token}`;
        }
      }
    });
  }, [steamIdPassword, steamIdTxt]);

  const LanguageBtn = (props: { label: string }) => {
    return <Button
      size="large"
      disabled={i18n.language === props.label.toLowerCase()}
      style={{
        marginLeft: "auto",
        color: i18n.language === props.label.toLowerCase() ? theme.palette.text.primary : theme.palette.text.disabled
      }}
      onClick={() => {
        i18n.changeLanguage(props.label.toLowerCase());
      }}
    >
      <ReactCountryFlag style={{ fontSize: "1.5em" }} countryCode={props.label === 'EN' ? 'GB' : props.label} />
    </Button>;
  }

  return <>
    <Dialog
      onClose={() => set_loginMethodDialogOpen(false)}
      open={loginMethodDialogOpen}
    >
      <Paper
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: theme.spacing(6),
          padding: theme.spacing(6)
        }}
      >
        <Typography>
          {t("login.with")}
        </Typography>

        <Button onClick={() => {
          window.location.href = API_URL + "/login"
        }}>
          <img src="https://community.cloudflare.steamstatic.com/public/images/signinthroughsteam/sits_01.png" alt="steam login" />
        </Button>

        <Divider style={{
          width: "100%",
          backgroundColor: theme.palette.text.disabled
        }} />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: theme.spacing(3)
          }}
        >
          <Typography>
            SteamID + {t("login.password")}
          </Typography>

          <div
            style={{
              display: "flex",
              gap: theme.spacing(2)
            }}
          >
            <TextField
              label={(steamIdTxt.length !== 0 && !googleSidValid) ? t("login.google.steam.error") : t("login.google.steam.input")}
              value={steamIdTxt}
              error={steamIdTxt.length !== 0 && !googleSidValid}
              onChange={(e) => {
                if (passwordLoginError) {
                  set_passwordLoginError(false);
                }

                set_googleSteamIdTxt(e.target.value);
              }}
              autoComplete="off"
              variant="outlined"
            />

            <TextField
              label={t("login.password")}
              autoComplete="off"
              variant="outlined"
              value={steamIdPassword ? steamIdPassword : ""}
              error={steamIdPassword != null && steamIdPassword.length !== 0}
              onChange={(e) => {
                if (passwordLoginError) {
                  set_passwordLoginError(false);
                }

                set_steamIdPassword(e.target.value);
              }}
              type="password"
            />

            <Button
              variant="contained"
              color="primary"
              disabled={steamIdTxt.length === 0 || !steamIdPassword || steamIdPassword.length === 0}
              onClick={onPasswordLogin}
            >
              Login
            </Button>
          </div>

          {passwordLoginError && <Typography
            style={{
              color: theme.palette.error.dark
            }}
          >
            {t("login.invalidPassword")}
          </Typography>}
        </div>
      </Paper>
    </Dialog>

    <div className={classes.bannerWrapper}>
      <Link to="/" className={classes.rlink}>
        <img src="/siteBanner.jpg" alt="romanian jumpers logo" className={classes.banner} />
      </Link>
    </div>

    <AppBar className={classes.navBar} position="static">
      <Container style={{ maxWidth: 'none' }}>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Link className={classes.rlink} to="/">
              <Button size="large">{t("menu.home")}</Button>
            </Link>
            {jwt && <>

              <Button
                size="large"
                id="demosButton"
                ref={anchofDemosRef}
                aria-controls={'demosMenu'}
                aria-expanded={true}
                aria-haspopup="true"
                onClick={handleDemosMenuToggle}
              >
                {t("demo.demos")} <ExpandMoreIcon />
              </Button>

              <Menu
                id="demosMenu"
                anchorEl={anchofDemosRef.current}
                open={demosMenuOpen}
                onClose={handleDemosMenuClose}
                MenuListProps={{
                  "aria-labelledby": "demosButton"
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                PaperProps={{
                  style: {
                    width: "9rem",
                    marginTop: "3rem",
                  }
                }}
              >
                <div onClick={handleDemosMenuClose}>
                  <Link className={classes.rlink} to="/demos/site">
                    <Button size="large">{t("menu.demos")}</Button>
                  </Link>
                </div>

                <div onClick={handleDemosMenuClose}>
                  <Link className={classes.rlink} to="/top/site">
                    <Button size="large">{t("menu.demosTop")}</Button>
                  </Link>
                </div>

                <div onClick={handleDemosMenuClose}>
                  <Link className={classes.rlink} to="/ljtop">
                    <Button size="large">{t("menu.lj_top")}</Button>
                  </Link>
                </div>

                <div onClick={handleDemosMenuClose}>
                  <Link className={classes.rlink} to="/upload">
                    <Button size="large">{t("menu.upload")} <PublishIcon /> </Button>
                  </Link>
                </div>
              </Menu>

              <Link className={classes.rlink} to="/shop">
                <Button size="large">{t("menu.shop")}</Button>
              </Link>
              <Link className={classes.rlink} to="/ratings">
                <Badge overlap="rectangular" badgeContent={t("new")} color="error">
                  <Button size="large">{t("menu.ratings")}</Button>
                </Badge>
              </Link>
              <Link className={classes.rlink} to="/challenges">
                <Button size="large">{t("menu.challenges")}</Button>
              </Link>
              <Link className={classes.rlink} to="/users">
                <Button size="large">{t("menu.users")}</Button>
              </Link>
              <Link className={classes.rlink} to="/rules">
                <Button size="large">{t("menu.rules")}</Button>
              </Link>
            </>
            }
            {!jwt && <>
              <Button size="large" onClick={onLogin}>{t("menu.login")}</Button>
            </>
            }
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {jwt && decodedJwtData && <>
              <Badge
                overlap="rectangular"
                invisible={watchlistNotificationCount === 0 && ljWatchlistNotificationCount === 0}
                badgeContent={
                  <div>
                    {watchlistNotificationCount} | {ljWatchlistNotificationCount}
                  </div>
                }
                color="error"
              >
                <Button
                  size="large"
                  id="profileButton"
                  aria-controls={'profileMenu'}
                  aria-haspopup={true}
                  aria-expanded={true}
                  onClick={handleProfileMenuToggle}
                  ref={anchofProfileRef}
                >
                  <Avatar style={{ marginRight: "0.5rem" }} src={decodedJwtData.avatar.small}></Avatar>{decodedJwtData.displayName}<ExpandMoreIcon />
                </Button>
              </Badge>

              <Menu
                id="profileMenu"
                anchorEl={anchofProfileRef.current}
                open={profileMenuOpen}
                onClose={handleProfileMenuClose}
                MenuListProps={{
                  'aria-labelledby': 'profileButton',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                PaperProps={{
                  style: {
                    marginTop: "3rem",
                  }
                }}
              >
                <div onClick={handleProfileMenuClose}>
                  <Link className={classes.rlink} to={`/profile/${decodedJwtData.steamID}`}>
                    <Button size="large">{t("menu.profile")}</Button>
                  </Link>
                </div>

                <div onClick={handleProfileMenuClose}>
                  <Link className={classes.rlink} to="/sentDemos">
                    <Button size="large">{t("menu.sent_demos")}</Button>
                  </Link>
                </div>

                <div onClick={handleProfileMenuClose}>
                  {(isAdmin(decodedJwtData) || decodedJwtData.type === UserType.ServerAdmin) &&
                    <Link className={classes.rlink} to={`/server_admin`}>
                      <Button size="large">Server Admin</Button>
                    </Link>}
                </div>

                <div onClick={handleProfileMenuClose}>
                  <Link className={classes.rlink} to="/" onClick={() => {
                    remove_cookies("jwt", {
                      path: "/",
                      secure: process.env.NODE_ENV === "production",
                      httpOnly: false,
                      domain: process.env.NODE_ENV === "production" ? ".romanian-jumpers.com" : "localhost",
                    });
                    set_jwt("");
                    set_decodedJwtData(undefined);
                  }}>
                    <Button size="large">{t("menu.logout")}</Button>
                  </Link>
                </div>
              </Menu>
            </>
            }
            <LanguageBtn label="RO" />
            <LanguageBtn label="EN" />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>

    <div className={classes.threeColumnLayout}>
      <Fade in timeout={500} unmountOnExit>
        <div className={classes.leftColumnWrapper}>
          <CustomPaper>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="overline" className={classes.sectionTitle}>
                {!jwt && t("login_first")}

                {jwt && t("welcome")}
              </Typography>

              <Tooltip title={`${t("join_discord")}`}>
                <IconButton
                  className={classes.discordIconBtn}
                  href="https://discord.gg/9HQnm5n7EN"
                  target="_blank"
                >
                  <img
                    src="/discord_icon.png"
                    alt="discord icon"
                    className={classes.discordIcon}
                    style={{
                      filter: hoveringDiscord ? "none" : "grayscale(100%)"
                    }}
                    onMouseEnter={() => set_hoveringDiscord(true)}
                    onMouseLeave={() => set_hoveringDiscord(false)}
                  />
                </IconButton>
              </Tooltip>
            </div>
          </CustomPaper>

          <ServerStatusComp
            jwt={jwt}
          />

          {jwt && <OnlineUsersComp users={onlineUsers} />}

          <ActiveChallengeLeaderboard />
        </div>
      </Fade>

      <Switch>
        <Route exact path={["/", "/news/:id"]}>
          <Home jwt={jwt} user={decodedJwtData} reloadWaitlistCount={reloadWaitlistCount} />
        </Route>

        <Route path="/analysis/:demoMD5/:map/:player/:time">
          <DemoAnalysis jwt={jwt} user={decodedJwtData} />
        </Route>

        <Route path="/mapdata/:map">
          <MapData jwt={jwt} user={decodedJwtData as User} />
        </Route>

        <Route path="/shop">
          <Shop jwt={jwt} user={decodedJwtData as User} />
        </Route>

        <Route path="/subscribe">
          <CustomPaper>
            <CardForm />
          </CustomPaper>
        </Route>

        <Route path="/payment_success/:priceID/:name/:wpn">
          <Success jwt={jwt} user={decodedJwtData as User} />
        </Route>

        <Route path="/login/:token">
          <LoginSuccess jwt_setter={jwt_setter} />
        </Route>

        <Route path="/banned">
          <UserBanned />
        </Route>

        <Route path={"/demos/:type"}>
          <CustomPaper>
            <Records jwt={jwt} user={decodedJwtData} />
          </CustomPaper>
        </Route>

        <Route path="/challenges" exact>
          <Challenges jwt={jwt} user={decodedJwtData} />
        </Route>

        <Route path="/challenges/create" exact>
          <CreateChallenge jwt={jwt} user={decodedJwtData} />
        </Route>

        <Route path={"/top/:type"}>
          <Top jwt={jwt} />
        </Route>

        <Route path={"/ljtop"}>
          <CustomPaper>
            <LJTop jwt={jwt} />
          </CustomPaper>
        </Route>

        <Route path={"/users"}>
          <Users jwt={jwt} />
        </Route>

        {/* <Route path="/pfizer">
          <Pfizer jwt={jwt}/>
        </Route>

        <Route path="/wrong">
          <Wrong jwt={jwt}/>
        </Route> */}

        <Route path={"/profile/:id"} render={(props) =>
          <div key={props.match.params.id}>
            {decodedJwtData &&
              <CustomPaper>
                <Profile
                  jwt={jwt}
                  user={decodedJwtData}
                />
              </CustomPaper>
            }
          </div>
        }>
        </Route>

        <Route path={"/upload"}>
          <CustomPaper>
            <Upload jwt={jwt} user={decodedJwtData} reloadWaitlistCount={() => { reloadWaitlistCount(); reloadLJWaitlistCount(); }} />
          </CustomPaper>
        </Route>

        <Route path={"/sentDemos"}>
          <TabbedDemosWaitlist
            jwt={jwt}
            user={decodedJwtData}
            reloadWaitlistCount={reloadWaitlistCount}
            reloadLJWaitlistCount={reloadLJWaitlistCount}
          />
        </Route>

        <Route path={"/rules"}>
          <CustomPaper>
            <Rules />
          </CustomPaper>
        </Route>

        <Route path="/server_admin">
          <ServerAdmin jwt={jwt} />
        </Route>

        <Route path="/privacy">
          <Privacy />
        </Route>

        <Route path="/ratings">
          <Ratings
            jwt={jwt}
            user={decodedJwtData}
          />
        </Route>
      </Switch>

      <Fade in timeout={500} unmountOnExit>
        <div className={classes.leftColumnWrapper}>
          <TabbedTopComp jwt={jwt} />
          <ServerTopComp jwt={jwt} />

          <CustomPaper>
            <ChatComp jwt={jwt} user={decodedJwtData} />
          </CustomPaper>
        </div>
      </Fade>
    </div>
  </>;
};
